<template>
<div id="page-Desgranar" class="non-printable">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height class="non-printable">
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Administrar Desgranes" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModalAjuste(1)" title="Desgranar artículo" v-tippy>
              <v-icon dark>call_split</v-icon>
            </v-btn>
            <v-btn fab dark x-small color="success" slot="activator" @click.native="openModalReagrupar(1)" title="Reagrupar artículos" v-tippy>
              <v-icon dark>mdi-group</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                    <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                    </template>
                    <template slot="codigo_articulo" slot-scope="props">
                            {{ props.row.codigo_articulo.join(", ") }}
                        </template>
                    <template slot="fecha" slot-scope="props">
                        {{ parse_date_time(props.row.fecha) }}
                    </template>

                    <template slot="actions" slot-scope="props">
                        <v-speed-dial direction="left" open-on-hover>
                            <template v-slot:activator>
                            <v-btn x-small fab dark color="primary">
                                <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                                <v-icon v-else>reorder</v-icon>
                            </v-btn>
                            </template>
                                <v-btn x-small fab dark color="blue lighten-3"
                                    @click.native="editarRegistro(props.row)" title="Editar Registro" v-tippy>
                                    <v-icon>edit</v-icon>
                                </v-btn>
                                <v-btn x-small fab dark color="pink" @click.native="deleteRegistro(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                                    <v-icon>delete</v-icon>
                                </v-btn>
                        </v-speed-dial>
                    </template>

                </v-client-table>
                <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL AJUSTE -->
  <v-dialog v-model="modalAjuste" max-width="60%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong> {{ reagrupar ? 'Reagrupar un artículo' : 'Desgranar un artículo' }}</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formajuste" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-autocomplete v-model="model.id_articulo" :items="articulos" :hide-no-data="true"
                    :loading="isLoading" 
                    :search-input.sync="search"                    
                    placeholder="Ingrese nombre de artículo para buscar"
                    prepend-icon="search" clearable
                    :hide-selected="true" item-text="nombre" item-value="_id" @change="consultar()"
                    label="Artículo" :rules="[rules.required]">
                </v-autocomplete>                
              </v-col>
            </v-row>
            <v-row v-if="reagrupar">
                <v-col sm="12" md="2" lg="2"></v-col>
                <v-col sm="12" md="3" lg="3">
                    <v-text-field label="Actual" v-model="actual" readonly
                    hint="Actual/Existencia" persistent-hint></v-text-field>
                </v-col>
                <v-col sm="12" md="4" lg="4">
                    <v-text-field label="Cantidad a Reagrupar" v-model="model.cantidad_original" ref="cantidadtraspaso"
                    :rules="[rules.required, validaCantidadFloat, validaActual, validaMultiplo]"
                    @input="calcularReagrupe()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="reagrupar">
                <v-col>
                    <br>
                    <v-chip text outlined color="info">Seleccione el artículo en el cual se va a reagrupar:</v-chip>
                    <v-client-table ref="vuetablearticulos" :data="articulosDesgrane" :columns="columnsArticulosReagrupe" :options="optionsArticulosReagrupe" class="elevation-2 pa-2">
                        <template slot="codigo_articulo" slot-scope="props">
                            {{ props.row.codigo_articulo.join(", ") }}
                        </template>
                        <template slot="cantidad_resultante" slot-scope="props">
                            1
                        </template>
                        <template slot="actions" slot-scope="props">
                            <v-icon color="gray" v-show="articulo_seleccionado != props.row"
                                @click.native="seleccionarArticuloReagrupe(props.row)">
                                mdi-checkbox-blank-outline</v-icon>
                            <v-icon color="success" v-show="articulo_seleccionado == props.row">
                                mdi-checkbox-marked</v-icon>
                        </template>
                    </v-client-table>
                </v-col>
            </v-row>            
            <!-- -------------------------------------->                     
            <v-row v-if="!reagrupar">
                <v-col sm="12" md="2" lg="2"></v-col>
                <v-col sm="12" md="3" lg="3">
                    <v-text-field label="Actual" v-model="actual" readonly
                    hint="Actual/Existencia" persistent-hint></v-text-field>
                </v-col>
                <v-col sm="12" md="4" lg="4">
                    <v-text-field label="Cantidad a Desgranar" v-model="model.cantidad_original" ref="cantidadtraspaso"
                    :rules="[rules.required, validaCantidadFloat, validaActual]"
                    @input="calcularDesgrane()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="!reagrupar">
                <v-col>
                    <br>
                    <v-chip text outlined color="info">Seleccione el artículo en el cual se va a desgranar:</v-chip>
                    <v-client-table ref="vuetablearticulos" :data="articulosDesgrane" :columns="columnsArticulos" :options="optionsArticulos" class="elevation-2 pa-2">
                        <template slot="codigo_articulo_equivalente" slot-scope="props">
                            {{ props.row.codigo_articulo_equivalente.join(", ") }}
                        </template>
                        <template slot="actions" slot-scope="props">
                            <v-icon color="gray" v-show="articulo_seleccionado != props.row"
                                @click.native="seleccionarArticulo(props.row)">
                                mdi-checkbox-blank-outline</v-icon>
                            <v-icon color="success" v-show="articulo_seleccionado == props.row">
                                mdi-checkbox-marked</v-icon>
                        </template>
                    </v-client-table>
                </v-col>
            </v-row>
            <v-row justify="center" align="center">
                    <span style="text-align:center;color:blue">
                        &nbsp;<br>
                        {{model.cantidad_original}} &nbsp;&nbsp; {{model.nombre_articulo}}<br>
                        Se convertirán en<br>
                        {{model.cantidad_equivalente}} &nbsp;&nbsp; {{model.nombre_articulo_equivalente}}<br>
                    </span>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalAjuste=false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn v-if="reagrupar" color="success" @click.native="realizarReagrupe()">
          <v-icon>done</v-icon> Reagrupar Artículos
        </v-btn>
        <v-btn  v-else color="success" @click.native="realizarDesgrane()">
          <v-icon>done</v-icon> Realizar Desgrane
        </v-btn>
        
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL REAGRUPAR
    Esta modal ya no se usó, ya que al tener 2 autocomplete en 2 v-dialog presentaba un error:
    Al buscar en el autocomplete, borraba el texto de busqueda al cargar los resultados, pero solo se da al abrir el segundo v-dialog
    al inicio en el primero no muestra ese error, hasta abrier el segundo ya lo borra en los dos al buscar
   -->
  <v-dialog v-model="modalReagrupar" max-width="60%" id="dialogReagrupe">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Reagrupar un artículo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formajuste2" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-autocomplete v-model="model.id_articulo" :items="articulos" :hide-no-data="true"
                    :loading="isLoading" ref="selectarticuloreagrupar" id="reagruparCombo"
                    :search-input.sync="search"                    
                    placeholder="Ingrese nombre de artículo para buscar"
                    prepend-icon="search" clearable
                    :hide-selected="true" item-text="nombre" item-value="_id" @change="consultar()"
                    label="Artículo" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="2" lg="2"></v-col>
                <v-col sm="12" md="3" lg="3">
                    <v-text-field label="Actual" v-model="actual" readonly
                    hint="Actual/Existencia" persistent-hint></v-text-field>
                </v-col>
                <v-col sm="12" md="4" lg="4">
                    <v-text-field label="Cantidad a Reagrupar" v-model="model.cantidad_original" ref="cantidadtraspaso"
                    :rules="[rules.required, validaCantidadFloat, validaActual, validaMultiplo]"
                    @input="calcularReagrupe()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <br>
                    <v-chip text outlined color="info">Seleccione el artículo en el cual se va a reagrupar:</v-chip>
                    <v-client-table ref="vuetablearticulos" :data="articulosDesgrane" :columns="columnsArticulosReagrupe" :options="optionsArticulosReagrupe" class="elevation-2 pa-2">
                        <template slot="codigo_articulo" slot-scope="props">
                            {{ props.row.codigo_articulo.join(", ") }}
                        </template>
                        <template slot="cantidad_resultante" slot-scope="props">
                            1
                        </template>
                        <template slot="actions" slot-scope="props">
                            <v-icon color="gray" v-show="articulo_seleccionado != props.row"
                                @click.native="seleccionarArticuloReagrupe(props.row)">
                                mdi-checkbox-blank-outline</v-icon>
                            <v-icon color="success" v-show="articulo_seleccionado == props.row">
                                mdi-checkbox-marked</v-icon>
                        </template>
                    </v-client-table>
                </v-col>
            </v-row>
            <v-row justify="center" align="center">
                    <span style="text-align:center;color:blue">
                        &nbsp;<br>
                        {{model.cantidad_original}} &nbsp;&nbsp; {{model.nombre_articulo}}<br>
                        Se convertirán en<br>
                        {{model.cantidad_equivalente}} &nbsp;&nbsp; {{model.nombre_articulo_equivalente}}<br>
                    </span>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalReagrupar=false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="realizarReagrupe()">
          <v-icon>done</v-icon> Reagrupar Artículos
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
import Vue from "vue";
import "jsbarcode";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
export default {
  components: {
    "PaginacionComponent": PaginacionComponent,
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
    search (val) {
        var self = this;
        //Si no ha escrito 4 letras o mas
        if (val == null || val.length<4 ) return

        // Si todavía esta buscando
        if (this.isLoading) return

        this.isLoading = true

        let data = {
                    "selector": {
                        "type":"articulos",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus":"Activo"
                    }
                };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.articulos = response.data.docs;
                    }else
                        this.articulos = [];

                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });

                }).then(()=>{ this.isLoading=false; });
      },
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('s.inventarios.desgranar')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
        this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
        if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
            this.$router.go();            
        }
      this.model.id_sucursal = this.$local_storage.get("sb_sucursal","");
      this.sb_sucursal = this.$local_storage.get("sb_sucursal","");
      this.model.nombre_sucursal = this.$local_storage.get("sb_nombre_sucursal","");

      this.model.usuario = this.$local_storage.get("sb_usuario");

  },
  data() {
    return {
        sucursal_activa:"",
        articulo_seleccionado:"",
        isLoading:false,
        sb_sucursal:"",
        model_vista: "",
        model_filters: "",
        props_paginacion: {
            infiniteHandler: this.infiniteHandler,
            total_registros: 0
        },
        fab: [],

        modalAjuste:false,
        modalReagrupar:false,
        reagrupar:false,

        barcode_orden:"",
        row_selected:"",
        articulos_vista:[],
        menu1:false,
        menu2:false,
        name: "datagrid",
        columns: [
            "cantidad_original",
            "codigo_articulo","nombre_articulo",
            "tipo",
            "cantidad_equivalente",
            "codigo_articulo_equivalente","nombre_articulo_equivalente",
            "usuario", "fecha"
        ],
        options: {
            filterable: ["cantidad_original",
            "codigo_articulo","nombre_articulo",
            "tipo",
            "cantidad_equivalente",
            "codigo_articulo_equivalente","nombre_articulo_equivalente",
            "usuario", "fecha"],
            headings: {
                "cantidad_original":"Cantidad",
                "codigo_articulo":"Codigo",
                "nombre_articulo":"Artículo",
                "cantidad_equivalente":"Cantidad Resultante",
                "codigo_articulo_equivalente":"Codigo Equivalente",
                "nombre_articulo_equivalente":"Artículo Equivalente"
            },
        },


        update: false,
        modelEditable:{},
        model:{
            _id:"",
            _rev:"",
            type: "lotes_desgrane",
            id_sucursal:"",
            nombre_sucursal:"",

            id_articulo:"",
            codigo_articulo:"",
            nombre_articulo:"",
            id_articulo_equivalente:"",
            codigo_articulo_equivalente:"",
            nombre_articulo_equivalente:"",
            cantidad_original:"",
            cantidad_equivalente:"",
            precio_compra:"",
            tipo:"", //Desgrane, Reagrupe

            lotes_origen:[],
            lotes_destino:[],

            usuario:"",
            fecha:"",
        },
        actual:"",
        cantidad:"",

        id_articulo:"",


        columnsArticulos: [ "codigo_articulo_equivalente","nombre_articulo_equivalente","cantidad","actions"],
        optionsArticulos: {
            filterable: ["codigo_articulo_equivalente","nombre_articulo_equivalente","cantidad"],
            headings: {
                "codigo_articulo_equivalente":"Código",
                "nombre_articulo_equivalente":"Artículo",
                "cantidad":"Cantidad Equivalente",
                "actions":"Seleccionar",
            },

        },

        columnsArticulosReagrupe: [ "codigo_articulo","nombre_articulo","cantidad","cantidad_resultante","actions"],
        optionsArticulosReagrupe: {
            filterable: ["codigo_articulo","nombre_articulo","cantidad"],
            headings: {
                "codigo_articulo":"Código",
                "nombre_articulo":"Artículo",
                "cantidad":"Cantidad a Reagrupar",
                "cantidad_resultante":"Cantidad Resultante",
                "actions":"Seleccionar",
            },

        },

        rules: {
            required: v => !!v || "Este campo es requerido",
        },
        search: "",
        registros: {
            selected: [],
            items: []
        },
        sucursales:[],
        all_sucursales:[],
        articulos:[],
        articulosDesgrane:[],
        breadcrumbs: [{
            text: "Inicio",
          disabled: false,
          to: "/index"
          },
          {
              text: "Inventario",
              href: "",
              disabled: true,
          },
          {
              text: "Desgranar Artículos",
              href: ""
          }
      ],
    };
  },
  methods: {
      infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      var where = {};
      if(this.sb_sucursal!=null && this.sb_sucursal!="0" && this.sb_sucursal!="")
        where = {"type":"lotes_desgrane","id_sucursal":this.sb_sucursal};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            sort: [{"fecha":"desc"}],
            use_index:"_design/0cf402ee3699b1a7d9e73cf1fddc46f662564f7a"
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    parse_date(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },

    getNombreArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.nombre;
        else
            return ""
    },


    getCodigoArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.codigo_barras;
        else
            return ""
    },

    validaActual: function(val){
        if(this.actual != null && this.actual !="" ){
            if(parseFloat(val) > parseFloat(this.actual))
                return "La cantidad es mayor a la actual";
            if(parseFloat(val) <= 0 )
                return "La cantidad debe ser mayor que 0";
        }
        return true;
    },


    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null) {
        if(!pattern.test(value))
            return "Cantidad no válida"
        if(parseFloat(value) <= 0 )
            return "La cantidad debe ser mayor que 0";
      }
    return true;

    },


    openModalAjuste: function(type) {
      if (type === 1) {
        this.update = false;

        this.model._id="";
        this.model._rev="";

        this.model.id_articulo="";
        this.model.codigo_articulo="";
        this.model.nombre_articulo="";
        this.model.id_articulo_equivalente="";
        this.model.codigo_articulo_equivalente="";
        this.model.nombre_articulo_equivalente="";
        this.model.cantidad_original="";
        this.model.cantidad_equivalente="";

        this.model.lotes_origen=[];
        this.model.lotes_destino=[];

        this.articulo_seleccionado = "";
        this.actual = "";
        this.articulosDesgrane = [];

      } else {
        this.update = true;
      }
      this.reagrupar = false;
      this.modalAjuste = true;
    },
    openModalReagrupar: function(type) {
      if (type === 1) {
        this.update = false;

        this.model._id="";
        this.model._rev="";

        this.model.id_articulo="";
        this.model.codigo_articulo="";
        this.model.nombre_articulo="";
        this.model.id_articulo_equivalente="";
        this.model.codigo_articulo_equivalente="";
        this.model.nombre_articulo_equivalente="";
        this.model.cantidad_original="";
        this.model.cantidad_equivalente="";

        this.model.lotes_origen=[];
        this.model.lotes_destino=[];

        this.articulo_seleccionado = "";
        this.actual = "";
        this.articulosDesgrane = [];

      } else {
        this.update = true;
      }
      this.reagrupar = true;
      //this.modalReagrupar = true;
      this.modalAjuste = true;
    },

    seleccionarArticulo: function(row){
        this.articulo_seleccionado = row;
        this.model.id_articulo_equivalente = row.id_articulo_equivalente;
        this.model.nombre_articulo_equivalente = row.nombre_articulo_equivalente;
        this.model.codigo_articulo_equivalente = row.codigo_articulo_equivalente;
        if(this.model.cantidad_original!=null && this.model.cantidad_original!="")
            this.model.cantidad_equivalente = parseFloat(this.model.cantidad_original)*parseFloat(row.cantidad);
    },
    calcularDesgrane: function(){
        if(this.articulo_seleccionado!=null && this.articulo_seleccionado!="" && this.model.cantidad_original!=null && this.model.cantidad_original!=""){
            this.model.cantidad_equivalente = parseFloat(this.model.cantidad_original)*parseFloat(this.articulo_seleccionado.cantidad);
        }
    },
    seleccionarArticuloReagrupe: function(row){
        this.articulo_seleccionado = row;
        this.model.id_articulo_equivalente = row.id_articulo;
        this.model.nombre_articulo_equivalente = row.nombre_articulo;
        this.model.codigo_articulo_equivalente = row.codigo_articulo;
        if(this.model.cantidad_original!=null && this.model.cantidad_original!="")
            this.model.cantidad_equivalente = parseFloat(this.model.cantidad_original)/parseFloat(row.cantidad);
    },
    calcularReagrupe: function(){
        if(this.articulo_seleccionado!=null && this.articulo_seleccionado!="" && this.model.cantidad_original!=null && this.model.cantidad_original!=""){
            this.model.cantidad_equivalente = parseFloat(this.model.cantidad_original)/parseFloat(this.articulo_seleccionado.cantidad);
        }
    },
    validaMultiplo: function(val){
        if(this.articulo_seleccionado!=null && this.articulo_seleccionado!="" && val!=null && val!=""){
            this.model.cantidad_equivalente = parseFloat(val)/parseFloat(this.articulo_seleccionado.cantidad);
            const pattern = /^[\d]*$/;
            if (this.model.cantidad_equivalente!=null && !pattern.test(this.model.cantidad_equivalente) ){
                return "Debe ser múltipo de "+this.articulo_seleccionado.cantidad;
            }
        }
        return true;
    },


    realizarDesgrane: function(){
        if (this.$refs.formajuste.validate()) {

            if(this.model.id_articulo_equivalente !=null && this.model.id_articulo_equivalente !=""){

                this.$swal({
                    type: "info",
                    title: "Confirmar",
                    text: "Se descontarán "+this.model.cantidad_original+" "+this.model.nombre_articulo+". Y Se aumentarán "+this.model.cantidad_equivalente+" "+this.model.nombre_articulo_equivalente+". ¿Desea continuar?",
                    footer: "",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                }).then(result => {
                    if (result.value) {

                        try {
                            window.dialogLoader.show('Espere un momento por favor..');

                            //afectar inventario para descontar el articulo original
                            var dataOriginal = {
                                id_sucursal: this.model.id_sucursal,  //Requerido
                                id_articulo: this.model.id_articulo,  //Requerido
                                cantidad: parseFloat(this.model.cantidad_original)*-1, //Requerido   Se hace negativo porque se descuenta de la sucursal origen
                                es_super: 1, //Requerido  - En este caso super siempre es 1 ya que el ajuste se hace directo, sin comprometer
                            }

                            var self = this;
                            window.funciones_lotes.ajusteInventario(dataOriginal).then(result =>{
                                self.model.lotes_origen = result;

                                //Se obtiene cantidad por cada desgrane
                                var cantidadxlote = parseFloat(self.model.cantidad_equivalente) / parseFloat(self.model.cantidad_original);

                            //Afectar el inventario destino
                                var bulkDestino = [];
                                result.forEach(function(lote){
                                    var dataDestino = {
                                        id_sucursal: self.model.id_sucursal,  //Requerido
                                        id_articulo: self.model.id_articulo_equivalente,  //Requerido
                                        id_compra: "",      //Requerido
                                        cantidad: (parseFloat(Math.abs(lote.cantidad)) * cantidadxlote), //Requerido positivo
                                        precio_compra: (parseFloat(lote.precio_compra) / cantidadxlote), //Requerido
                                        fecha_caducidad: lote.fecha_caducidad, //Opcional
                                        fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"),  //Requerido
                                        origen: "desgrane",
                                        usuario: self.$local_storage.get("sb_usuario"),
                                        };
                                    bulkDestino.push(dataDestino);

                                });

                                window.funciones_lotes.nuevaEntradaInventarioBulk(bulkDestino).then(result2 =>{

                                    self.model.lotes_destino = result2;
                                    self.model.fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                                    self.model.tipo = "Desgrane";

                                    delete self.model._id;
                                    delete self.model._rev;

                                    window.axios
                                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', self.model)
                                        .then(response => {
                                            window.dialogLoader.showSnackbar('El desgrane se realizó correctamente..', {
                                                color: 'success'
                                            });
                                            self.modalAjuste = false;
                                            self.registros.items = [];
                                            self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                        })
                                        .catch(error => {
                                            self.$swal({
                                                type: "error",
                                                title: "¡Operación no Permitida!",
                                                text: "Ocurrió un error al registrar el desgrane. Favor de comunicarse con soporte.",
                                                footer: error
                                            });
                                        }).then(() => {
                                            window.dialogLoader.hide();
                                        });


                                }).catch(err => {
                                    window.dialogLoader.hide();
                                    self.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al ingresar los articulos desgranados. Favor de comunicarse con soporte.",
                                        footer: err
                                    });
                                });



                            }).catch(err => {
                                self.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al descontar el producto origen. Favor de comunicarse con soporte.",
                                    footer: err
                                });
                                window.dialogLoader.hide();
                            });
                        } catch (error) {
                            console.log(error);
                            this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió una excepción. Favor de comunicarse con soporte.",
                                    footer: error,
                                });
                            window.dialogLoader.hide();
                        }

                    }
                });
            } else {
                this.$swal({
                    type: "info",
                    title: "Cuidado",
                    text: "Debe seleccionar un artículo al cual se va a desgranar. Favor de verificar.",
                    footer: "",
                });
            }
        }
    },

    realizarReagrupe: function(){
        if (this.$refs.formajuste.validate()) {

            if(this.model.id_articulo_equivalente !=null && this.model.id_articulo_equivalente !=""){

                this.$swal({
                    type: "info",
                    title: "Confirmar",
                    text: "Se descontarán "+this.model.cantidad_original+" "+this.model.nombre_articulo+". Y Se aumentarán "+this.model.cantidad_equivalente+" "+this.model.nombre_articulo_equivalente+". ¿Desea continuar?",
                    footer: "",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                }).then(result => {
                    if (result.value) {

                        try {
                            window.dialogLoader.show('Espere un momento por favor..');

                            //afectar inventario para descontar el articulo original
                            var dataOriginal = {
                                id_sucursal: this.model.id_sucursal,  //Requerido
                                id_articulo: this.model.id_articulo,  //Requerido
                                cantidad: parseFloat(this.model.cantidad_original)*-1, //Requerido   Se hace negativo porque se descuenta de la sucursal origen
                                es_super: 1, //Requerido  - En este caso super siempre es 1 ya que el ajuste se hace directo, sin comprometer
                            }

                            var self = this;
                            window.funciones_lotes.ajusteInventario(dataOriginal).then(result =>{
                                self.model.lotes_origen = result;

                                //Se obtiene cantidad por cada reagrupe
                                var cantidadxlote = parseFloat(self.model.cantidad_original) / parseFloat(self.model.cantidad_equivalente);

                            //Afectar el inventario destino
                                var bulkDestino = [];
                                result.forEach(function(lote){
                                    var dataDestino = {
                                        id_sucursal: self.model.id_sucursal,  //Requerido
                                        id_articulo: self.model.id_articulo_equivalente,  //Requerido
                                        id_compra: "",      //Requerido
                                        cantidad: (parseFloat(Math.abs(lote.cantidad)) / cantidadxlote), //Requerido positivo
                                        precio_compra: (parseFloat(lote.precio_compra) * cantidadxlote), //Requerido
                                        fecha_caducidad: lote.fecha_caducidad, //Opcional
                                        fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"),  //Requerido
                                        origen: "reagrupe",
                                        usuario: self.$local_storage.get("sb_usuario"),
                                        };
                                    bulkDestino.push(dataDestino);

                                });

                                window.funciones_lotes.nuevaEntradaInventarioBulk(bulkDestino).then(result2 =>{

                                    self.model.lotes_destino = result2;
                                    self.model.fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                                    self.model.tipo = "Reagrupe";

                                    delete self.model._id;
                                    delete self.model._rev;

                                    window.axios
                                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', self.model)
                                        .then(response => {
                                            window.dialogLoader.showSnackbar('El reagrupe se realizó correctamente..', {
                                                color: 'success'
                                            });
                                            //self.modalReagrupar = false;
                                            self.modalAjuste = false;
                                            self.registros.items = [];
                                            self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                        })
                                        .catch(error => {
                                            self.$swal({
                                                type: "error",
                                                title: "¡Operación no Permitida!",
                                                text: "Ocurrió un error al registrar el reagrupe. Favor de comunicarse con soporte.",
                                                footer: error
                                            });
                                        }).then(() => {
                                            window.dialogLoader.hide();
                                        });


                                }).catch(err => {
                                    window.dialogLoader.hide();
                                    self.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al ingresar los articulos reagrupados. Favor de comunicarse con soporte.",
                                        footer: err
                                    });
                                });



                            }).catch(err => {
                                self.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al descontar el producto origen. Favor de comunicarse con soporte.",
                                    footer: err
                                });
                                window.dialogLoader.hide();
                            });
                        } catch (error) {
                            console.log(error);
                            this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió una excepción. Favor de comunicarse con soporte.",
                                    footer: error,
                                });
                            window.dialogLoader.hide();
                        }

                    }
                });
            } else {
                this.$swal({
                    type: "info",
                    title: "Cuidado",
                    text: "Debe seleccionar un artículo al cual se va a reagrupar. Favor de verificar.",
                    footer: "",
                });
            }
        }
    },

    consultar: function(){
        if(this.model.id_sucursal != null && this.model.id_sucursal != "" && this.model.id_sucursal != "0"
            && this.model.id_articulo != null && this.model.id_articulo != "") {

            window.dialogLoader.show('Espere un momento por favor..');

            this.model.nombre_articulo = this.getNombreArticulo(this.model.id_articulo);
            this.model.codigo_articulo = this.getCodigoArticulo(this.model.id_articulo);

            let data = {
                id_articulo: this.model.id_articulo,  //Requerido
                id_sucursal: this.model.id_sucursal,  //Requerido
            };

            var self = this;
            self.articulosDesgrane = [];
            window.funciones_lotes.consultaExistencia(data).then(result =>{
                if(result.length>0){
                    self.actual = result[0].existencia;

                    if(this.reagrupar == true){
                        var busca = encodeURI(escape(self.model.id_articulo));
                        window.axios
                        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_equivalentes?key=\"'+busca+'\"')
                        .then(async responseArticulo => {

                            if(responseArticulo.data != null && responseArticulo.data.rows!=null && responseArticulo.data.rows.length>0){
                                for(var k in responseArticulo.data.rows){

                                    var articulo = responseArticulo.data.rows[k].value;

                                        var record = {
                                            "id_articulo": articulo.id_articulo,
                                            "codigo_articulo":articulo.codigo_articulo,
                                            "nombre_articulo":articulo.nombre_articulo,
                                            "id_articulo_equivalente": articulo.id_articulo_equivalente,
                                            "codigo_articulo_equivalente": articulo.codigo_articulo_equivalente,
                                            "nombre_articulo_equivalente": articulo.nombre_articulo_equivalente,
                                            "cantidad": articulo.cantidad
                                        };
                                        self.articulosDesgrane.push(record);

                                }
                            } else {
                                self.articulosDesgrane = [];
                                self.$swal({
                                    type: "info",
                                    title: "¡Operación no Permitida!",
                                    text: "No hay artículos relacionados al reagrupe del articulo seleccionado. Favor de verificarlo en el catálogo de Artículos Equivalencias.",
                                    footer: ""
                                });
                            }

                        })
                        .catch(error => {
                            self.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al obtener los artículos equivalentes. Intente nuevamente.",
                                footer: ""
                            });
                            console.log(error);
                        }).then(() => {
                            window.dialogLoader.hide();
                        });

                    } else {
                        var filter = this.articulos.find(e => e._id == self.model.id_articulo);
                        if(filter){
                            if(filter.equivalencias.length>0){
                                filter.equivalencias.forEach( e => {
                                    var record = {
                                        "id_articulo": filter._id,
                                        "codigo_articulo":filter.codigo_barras,
                                        "nombre_articulo":filter.nombre,
                                        "id_articulo_equivalente": e.id_articulo_equivalente,
                                        "codigo_articulo_equivalente": e.codigos_equivalente,
                                        "nombre_articulo_equivalente": e.nombre_articulo_equivalente,
                                        "cantidad": e.cantidad
                                    };
                                    self.articulosDesgrane.push(record);
                                });
                                window.dialogLoader.hide();
                            } else {
                                window.dialogLoader.hide();
                                self.articulosDesgrane = [];
                                self.$swal({
                                    type: "info",
                                    title: "¡Operación no Permitida!",
                                    text: "No hay artículos relacionados al desgrane del articulo seleccionado. Favor de verificarlo en el catálogo de Artículos Equivalencias.",
                                    footer: ""
                                });
                            }
                        } else {
                            window.dialogLoader.hide();
                            self.articulosDesgrane = [];
                            self.$swal({
                                type: "info",
                                title: "¡Operación no Permitida!",
                                text: "No hay artículos relacionados al desgrane del articulo seleccionado. Favor de verificarlo en el catálogo de Artículos Equivalencias.",
                                footer: ""
                            });
                        }

                    }

                } else {
                    window.dialogLoader.hide();
                    self.$swal({
                        type: "info",
                        title: "¡Operación no Permitida!",
                        text: "No hay entrada en el inventario para este artículo.",
                        footer: ""
                    });
                    self.model.id_articulo = "";
                    self.actual = "";
                }

            }).catch(err => {
                self.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al consultar existencia de artículo.",
                    footer: err
                });
                window.dialogLoader.hide();
            }).then(() => {
                self.model.cantidad_original="";
                self.$nextTick(() => self.$refs.cantidadtraspaso.focus());

            });
      }
    },


  }
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
  display: none;
}

</style>
